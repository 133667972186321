// ###########################################
// # variaveis de configuração do sistema    #
// ###########################################

var globals = {
  REACT_APP_APIBASE: process.env.REACT_APP_APIBASE,
  REACT_APP_ENDERECO: process.env.REACT_APP_ENDERECO,
  REACT_APP_BUSINESS: process.env.REACT_APP_BUSINESS 

};
export default globals;
